<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiFormatListChecks }}
      </v-icon>
      <span class="text-break">Permission Settings</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="permissionData.id"
              label="Permission ID"
              dense
              outlined
              :disabled="true"
            ></v-text-field>
          </v-col>

          <!--
          <v-col
            v-if="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="permissionData.label"
              label="Label"
              readonly
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>
          -->

          <v-col
            cols="12"
            md="6"
          >
            <div v-if="permissionData.status === 'LOCKED'">
              Status: <strong>{{ permissionData.control }}</strong>
            </div>
            <v-select
              v-if="permissionData.control !== 'LOCKED'"
              v-model="permissionData.control"
              :rules="requiredRule"
              :disabled="mode === 'update'"
              dense
              outlined
              label="Control *"
              :items="controls"
              item-value="id"
              item-text="label"
            ></v-select>
          </v-col>

          <!--
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="permissionData.subtype"
              :rules="requiredRule"
              dense
              outlined
              label="Subtype *"
              :items="subtypes"
            ></v-select>
          </v-col>
          -->

          <v-col
            cols="12"
            md="6"
          >
            <div v-if="permissionData.status === 'LOCKED'">
              Status: <strong>{{ permissionData.status }}</strong>
            </div>
            <v-select
              v-if="permissionData.status !== 'LOCKED'"
              v-model="permissionData.status"
              :rules="requiredRule"
              dense
              outlined
              label="Status *"
              :items="status"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiFormatListChecks,
} from '@mdi/js'

export default {
  /**
   * props
   */
  props: {
    permissionData: {
      type: Object,
      default: () => {},
    },
    permissionDataOriginal: {
      type: Object,
      default: () => {},
    },
    controls: {
      type: Array,
      default: () => [],
    },
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      requiredRule: [
        value => !!value || 'Required',
      ],
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * isReadOnly
     */
    isReadOnly() {
      const read_only = this.mode === 'update'
        && this.permissionDataOriginal?.value
        && this.permissionDataOriginal?.value !== ''
        && (this.permissionDataOriginal.value.includes('quasr.io') || this.permissionDataOriginal.value === 'openid')

      return read_only || false
    },
  },

  /**
   * setup
   */
  setup() {
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]
    const subtypes = [
      { text: 'Scope', value: 'scope' },
      { text: 'Text', value: 'text' },
    ]

    return {
      status,
      subtypes,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiFormatListChecks,
      },
    }
  },
}
</script>
